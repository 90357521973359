.App {
    height: 100%;
    text-align: center;
    display: flex;
    flex-grow: 1;
    overflow: scroll;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #ffffff 0%, #e2e2e2 100%);
}

body {
    overflow: hidden;
    display: flex;
}

#root {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
}

@media screen and (max-width: 600px) {
    #root {
        overflow: scroll;
    }
}
.Admin-App {
    height: 100%;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    background: #f0f2f5;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.bm-burger-button {
    position: absolute;
    width: 32px;
    height: 15px;
    right: 32px;
    top: 33px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    height: 2px !important;
    width: 18px;
    border-radius: 2px;
    background: #707070;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #707070;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    position: absolute;
    width: 32px !important;
    height: 32px !important;
    right: 41px !important;
    top: 21px !important;
}

.burger-menu-cross {
    height: 24px !important;
    width: 24px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    width: 2px !important;
    height: 24px !important;
    border-radius: 8px;
    background: #707070;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    top: 0;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
}

.ant-drawer-content-wrapper {
    width: calc(100vw - 64px) !important;
    margin-left: 32px;
    height: 80vh !important;
}

.ant-drawer-content {
    overflow: auto;
}

.ant-drawer-body {
    padding: 0;
}

form {
    width: 100%;
    height: 100%;
}

input {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 18px;
    color: #8b96a3;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    width: calc(100%);
    background-color: transparent;
    font-size: 18px;
    line-height: 18px;
}

textarea {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 18px;
    color: #8b96a3;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    width: calc(100%);
    background-color: transparent;
    font-size: 18px;
    line-height: 18px;
}

b {
    font-weight: bold;
}

h1,
h2,
h3 {
    background: none !important;
}

::placeholder {
    color: #8b96a3;
}
/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-input">
 *       <input />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border-radius: 1px;

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
}

.react-tags__create_daily_five_post {
    position: relative;
    padding: 6px 0 0 0;
    border-radius: 1px;

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
}

.react-tags__search-input {
    width: 100%;
    /* border: 1px solid grey; */
    padding: 8px 8px;
    /* border-radius: 8px; */
    border-bottom: 2px solid;
    border-bottom-color: #757575;
    border-radius: 0;
    background-color: #fafafa;

    /* display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    outline: none;
    font-size: 1rem;
    height: 2.5rem;
    line-height: 2.5rem;
    
    border-radius: 0;
    border-bottom: 2px solid;
    border-color: inherit;
    background-color: #fafafa;
    border-bottom-color: #757575; */
}
.react-tags__search-input__create_daily_five_post {
    width: 100%;
    /* border: 1px solid grey; */
    padding: 8px 8px;
    /* border-radius: 8px; */
    border-bottom: 1px solid;
    border-bottom-color: #e0e0e0;
    border-radius: 0;
    background-color: #fafafa;
}
.react-tags.is-focused {
    border-color: #b1b1b1;
}

.react-tags__selected {
    display: block;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    background: #f1f1f1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag:after {
    content: '\2715';
    color: #aaa;
    margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #b1b1b1;
}

.react-tags__search {
    display: inline-block;
    width: 100%;

    /* match tag layout */
    /* padding: 7px 2px;
    margin-bottom: 6px; */

    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }
}

.react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    max-height: 260px;
    z-index: 99;
    left: unset;
    max-height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}

.react-tags__suggestions mark {
    padding: 0;
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.react-calendar {
    border: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__tile--active {
    background: #eb4969 !important;
    border-radius: 50px;
    padding: 0.85em 0.6em;
    margin: 0em 1em;
}

.react-calendar__tile {
    line-height: 25px;
}

.react-calendar__navigation__label:disabled {
    background-color: transparent !important;
    cursor: default;
}

.react-calendar__tile:hover:not(:disabled) {
    border-radius: 50px;
}

.react-calendar__month-view__weekdays__weekday > abbr[title] {
    text-decoration: none !important;
    text-transform: none !important;
    color: #424242;
    font-weight: bold;
    font-size: 0.8rem !important;
}

.react-calendar__navigation button {
    border-radius: 50px;
}
